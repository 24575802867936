.NewSaving form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

  @media all and (min-width: 480px) {
    .NewSaving {
      padding: 60px 0;
    }
  
    .NewSaving form {
      margin: 0 auto;
      max-width: 320px;
    }

    .NewSaving select {
      margin: 0 auto;
      max-width: 320px;
    }
  }