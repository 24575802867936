.PayCalculator .lander {
  padding: 80px 0;
  text-align: center;
}

.PayCalculator .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.PayCalculator .table {
  padding: 80px 0;
  text-align: center;
}