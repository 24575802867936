.NewIncome form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

  @media all and (min-width: 480px) {
    .NewIncome {
      padding: 60px 0;
    }
  
    .NewIncome form {
      margin: 0 auto;
      max-width: 320px;
    }

    .NewIncome select {
      margin: 0 auto;
      max-width: 320px;
    }
  }