.Overview .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Overview .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .Overview .table {
    padding: 80px 0;
    text-align: center;
  }

  .react-bootstrap-table table {
    table-layout: auto;
    size: sm
  }

  .underline {
    border-bottom-width: 4px;
   }