.Income .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Income .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .Income .table {
    padding: 80px 0;
    text-align: center;
  }